import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaSteam } from "react-icons/fa";
import { FaBilibili } from "react-icons/fa6";
import { MdAlternateEmail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>

        {/* <Col md="4" className="footer-copywright">
        <h3><a href="https://beian.miit.gov.cn/" target="_blank">
            蜀ICP备2024105398号-1</a>
            </h3>
        </Col> */}
        <Col md="4" className="footer-copywright">
          <h3>Designed by <a href="https://github.com/soumyajit4419/Portfolio" target="_blank">Portfolio</a>.
          </h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3> Copyright © 2019 - {year} Kitty.</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://bilibili.com"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaBilibili />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="to:autherwong394@gmail.com"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdAlternateEmail />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://x.com"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://store.steampowered.com"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSteam />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
