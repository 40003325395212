import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillAliwangwang,
  AiFillInstagram,
} from "react-icons/ai";
import { FaSteam } from "react-icons/fa";
import { FaBilibili } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "1.7em" }}>
              <span className="purple"> 一剪梅·红藕香残玉簟秋 </span>
            </h1>
            <p className="home-about-body">
              红藕香残玉簟秋，轻解罗裳，独上兰舟。
              <br />
              <br />云中谁寄锦书来？雁字回时，月满西楼。

              <br />
              <br />
              花自飘零水自流，一种相思，两处闲愁。

              <br />
              <br />
              此情无计可消除，才下眉头，却上心头。

            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <p>
              <span className="purple">人生若梦 转眼皆空 </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://bilibili.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaBilibili />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="to:autherwong394@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <MdAlternateEmail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaXTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://store.steampowered.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaSteam />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
